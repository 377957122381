import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../translator';
import './style.css';
const LanguageSelector = () =>{
    const [lang, setLang] = useState('default');


    const { i18n } = useTranslation();

  const handleLanguageChange = (event:SelectChangeEvent) => {
    const selectedLanguage = event.target.value;
    setLang(event.target.value as string);
    i18n.changeLanguage(selectedLanguage);
  };
    return(
        <div className="lang-container flex-row">
       <FormControl className='lang-selector' size='small'>
        <Select
          value={lang || 'default'}
          onChange={handleLanguageChange}
          
        >
          <MenuItem value={'default'} disabled><img src='./images/homeImages/language.png' className='lang-icon' alt=''/></MenuItem>
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'tel'}>Telugu</MenuItem>
          <MenuItem value={'hin'}>Hindi</MenuItem>
        </Select>
      </FormControl>
        </div>
    )
}

export default LanguageSelector;