import { useState } from 'react';
import Data from '../../Data.json';
import './style.css';
const BottomNavBar: React.FC  = () =>{
    const [selected,setSelectedId] = useState(''); 

    const scrollToSection = (id:string) => {
        const element = document.getElementById(id);
        setSelectedId(id);
        if (element) {
            const yOffset = -50; 
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            
        }
    };
    return(
        <div className="bottom-nav-bar flex-col justify-center align-center box-shadow">
           <div className='bottom-nav-icons flex-row justify-center'>
    {Data.mobilenavIcons
        .sort((a, b) => a.id_name.localeCompare(b.id_name)) 
        .map((icon, index) => (
            <button 
                className={`bottom-nav-btn pdngLeftRight ${selected === `${icon.id_name}` ? 'selected-btn' : ''} ${icon.id_name}`} 
                key={index} 
                onClick={() => scrollToSection(`${icon.id_name}`)}
            >
                <img src={icon.src} alt='icon' className={`${icon.id_name}-img`} />
            </button>
        ))
    }
</div>
<div className='bottom-last'></div>
        </div>
    )
}
export default BottomNavBar;