import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import Data from '../../Data.json';
import './style.css';


const Navbar = () =>
{
    const {t} = useTranslation();
    const [showNavOptions,setShowOptions] = useState(false);
    useEffect(() =>{
        const handleResize = () =>{
            if(window.innerWidth >= 1000){
                setShowOptions(true);
            }
            else{
                setShowOptions(false);
            }
        };

        handleResize();

        window.addEventListener('resize',handleResize);
        return() =>{
            window.removeEventListener('resize',handleResize);
        }
    },[]);

    // const [selected,setSelectedId] = useState(''); 

    const scrollToSection = (id:string) => {
        const element = document.getElementById(id);
        // setSelectedId(id);
        if (element) {
            const yOffset = -50; // Stop 50px before the content
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            
        }
    };

    return(
        <div className="flex-row navbar align-center" >
            <div className='nav-left-part flex-minWidth-row  pdng-left'>
                <img src='./images/sda_logo.svg' className='sda-logo' alt='logo'/>
            {showNavOptions && <div className='navigation'>
                {Data.mobilenavIcons.map((nav,index)=>(
                    <button key={index} className='nav-btn text-size' data-tooltip-id='navigation' data-tooltip-content={nav.id_name} onClick={()=>{scrollToSection(`${nav.id_name}`)}}>
                        <img src={nav.src} alt='nav'/>
                    </button>
                ))}
            </div>
             }
            </div>
           
                <div className='location-group flex-row flex-auto-row' data-tooltip-id='location-group' data-tooltip-content="View in Maps">
               <a href='https://maps.app.goo.gl/zT7118deyARxUeSZ7' target="_blank" rel='noopener noreferrer'  className='flex-auto-row pdng-right navlink-text'>
                   <div className='location'>
                   <span className='church-name text-shadow'>{t('churchName')}</span>
                   <span className='church-area'>{t('churchArea')}</span>
                   </div>
                   <div className='location-icon'>
                    <img src='./images/location.svg' className='icon drop-shadow' alt='icon'/>
                   </div>
               </a> 
               <Tooltip id='location-group' place='bottom' className='tooltip location-tooltip' variant='info'/>
               <Tooltip id='navigation' className='tooltip nav-tooltip' place='bottom' variant='dark'/>
               </div>
        </div>
    )
}

export default Navbar;