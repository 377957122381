import axios from 'axios';
import { useEffect, useState } from 'react';
import './App.css';
import BottomNavBar from './components/BottomNavBar/index.';
import Main from './components/Main';
import Navbar from './components/Navbar';

function App(){

  const [showNavbar, setShowNavbar] = useState(false);

  const visit = () => {
    let visit = localStorage.getItem('visit')
    console.log("visit", visit)
    if(!visit){
      axios.post('https://api.sdabanjarahills.in/visit')
      .then((res)=>{
        console.log('res', res.data)
        localStorage.setItem('visit', "visited")
      })
      .catch(e=>console.log(e))      
    }
  }

  useEffect(() => {
    // Function to check window width and update state
    const handleResize = () => {
      if (window.innerWidth <= 999) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    // Initial check
    handleResize();
    visit();

    // Add event listener on window resize
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
return (
    <div className="App">
      <Navbar/>
      <Main />
      {
        showNavbar && (<BottomNavBar/>)
      }
    </div>
  );
};

export default App;
