import React from 'react';
import './Loader.css'; // Ensure to link the above CSS

const FullPageLoader: React.FC = () => {
    return (
        <div className="loader-wrapper">
            <div className="circle-loader"></div>
        </div>
    );
};

export default FullPageLoader;
