import { lazy, Suspense } from 'react';
import LanguageSelector from './Components/Language'
import PopUp from './Components/PopUp'
import './style.css'
import FullPageLoader from '../Loading';
const Main = () =>
{
    const About = lazy(() => import('./Components/About'));
    const ImageCarousel = lazy(() => import('./Components/Carousel'));
    const Events = lazy(() => import('./Components/Events'));
    const Section = lazy(() => import('./Components/Sections'));
    const Footer = lazy(() => import('./Components/Footer'));

    return(
        <div className="main" id="Home">
            <Suspense fallback={<FullPageLoader />}>
                <ImageCarousel/>
                <About />
                <Events/>
                <Section />
                <LanguageSelector/>
                <PopUp/>
                <Footer />
            </Suspense>
        </div>
    )
}
export default Main;