import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Data from '../../../../Data.json';
import './style.css';
import axios from 'axios';
import FullPageLoader from '../../../Loading';

const PopUp = () => 
{
  const [ loading, setLoading ] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [submitted,setSubmitted] = useState(false);
  const [username,setUsername] = useState('');
  const [feedback,setFeedback] = useState('');
  const [rating, setRating] = useState('');

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setSubmitted(false);
    setUsername('');
    setFeedback('');
    setRating('');
  };

  const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => 
  {
    if(!loading)
    {
      setLoading(true);
      e.preventDefault();
      Data.User_Feedback.username = username;
      Data.User_Feedback.feedback = feedback;
      Data.User_Feedback.rating = rating;
      const item = {
        Username :username?.trim(),
        Feedback : feedback?.trim(),
        Rating :Number(rating),
      }
    try
    {

      await axios.post('https://api.sdabanjarahills.in/feed/createFeed', item)
    }
    catch(e)
    {
      console.log(e);
    }
    finally
    {
      setLoading(false);
      setSubmitted(true);
    }
  }
  };  

  return (
    <div>
      {loading && <FullPageLoader />}
      <button onClick={togglePopup} className="open-popup-btn" >
        <img src='./images/homeImages/feedback_icon.svg' alt='icon' />
      </button>

      {isOpen && (
        <div className="popup-container flex-center ">
          <div className="popup box-shadow">
         <div className='close-group'>
          <button type="button" onClick={togglePopup} className="close-btn  text-shadow "><span>X</span></button>
         </div>
            <div className='flex-col fdbk-form'>
                <h2 className='margin-bottom heading'>Help us to Improve</h2>

                <hr/>
              {submitted ? <div className='submitted-content'>
                <span className='text-shadow text-size'>Thank You For providing the feedback 😊❤️</span>
              </div> 

              :
              <form className='flex-col form-container' onSubmit={handleSubmit}>

              <label className='label text-shadow text-size'>Username :</label>
              <input className='input'value={username} placeholder='Enter Username' onChange={(e)=>{setUsername(e.target.value.replace(/^\s+/, ''))}}/>

              <label className='label text-shadow text-size'>Feedback :</label>
              <textarea className='input' value={feedback} placeholder='Give Your Feedback Here..' onChange={(e)=>{setFeedback(e.target.value.replace(/^\s+/, ''))}}/>

              <label className='label text-shadow text-size'>Rating :</label>  
              <div className="rating-stars-container mrgn-top-bottom drop-shadow">
              {[5, 4, 3, 2, 1].map((star) => (
                <React.Fragment key={star}>
                  <input
                    value={`${star}`}
                    name="star"
                    id={`star-${star}`}
                    type="radio"
                    checked={rating === `${star}`}
                    onChange={(e)=>{setRating(e.target.value)}}
                  />
                  <label htmlFor={`star-${star}`} className="star-label">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        pathLength="360"
                      ></path>
                    </svg>
                  </label>
                </React.Fragment>
              ))}
            </div>
              <button className='submit-btn justify-center align-center text-size text-shadow'  type='submit' >Submit</button>
            </form>
              }
              </div>
            </div>
        </div>
      )}
      <Tooltip id='feedback-popup'/>
    </div>
  );
};

export default PopUp;
