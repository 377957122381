import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Import translation files
import i18nextBrowserLanguagedetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/language.json';
// import translationHIN from './locales/hin/language.json';
import translationHIN from './locales/hin/language.json';
import translationTEL from './locales/tel/language.json';
// Initialize i18next
i18n
  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      tel: { translation: translationTEL },
      hin:{translation: translationHIN }
    },
    fallbackLng: 'en',
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
